jQuery(document).ready(function($) {
    
   $('.reviews__slider').slick({
        arrows: true,
        dots: true,
        prevArrow: $('.reviews__buttons-prev'),
        nextArrow: $('.reviews__buttons-next'),
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
         {
           breakpoint: 993,
           settings: {
             slidesToShow: 2
           }
         },
         {
           breakpoint: 577,
           settings: {
             slidesToShow: 1
           }
         }
       ]
   });

   $('.switcher').click(function() {
      $('.header').toggleClass('switcher-active');
   });

   document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
          e.preventDefault();
  
          document.querySelector(this.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
          });
      });
  });

});

